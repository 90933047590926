import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import OrderEditorTemplate from './OrderEditorTemplate';
import {useTitle} from "../util/TitleContext";

const orders = [
    {
        id: '1',
        workId: '21735/112',
        customer: 'Autógyár Kft.',
        productName: 'Címer',
        amount: 10,
        deadline: '2025-01-12',
        unitPrice: 35000,
        createdAt: '2025-01-01 18:32:12',
        updatedAt: '2025-01-01 18:32:12',
        manufacturingProcesses: []
    },
    {
        id: '2',
        workId: '21736/113',
        customer: 'Építőipari Zrt.',
        productName: 'Fém alkatrész',
        amount: 25,
        deadline: '2025-02-15',
        unitPrice: 12500,
        createdAt: '2025-01-01 18:32:12',
        updatedAt: '2025-01-01 18:32:12',
        manufacturingProcesses: []
    },
    {
        id: '3',
        workId: '21737/114',
        customer: 'Bútorgyár Bt.',
        productName: 'Fa elem',
        amount: 50,
        deadline: '2025-03-20',
        unitPrice: 50000,
        createdAt: '2025-01-01 18:32:12',
        updatedAt: '2025-01-01 18:32:12',
        manufacturingProcesses: []
    },
    {
        id: '4',
        workId: '21738/115',
        customer: 'Ipari Megoldások Kft.',
        productName: 'Csapágy',
        amount: 100,
        deadline: '2025-04-01',
        unitPrice: 300000,
        createdAt: '2025-01-01 18:32:12',
        updatedAt: '2025-01-01 18:32:12',
        manufacturingProcesses: []
    },
    {
        id: '5',
        workId: '21739/116',
        customer: 'Élelmiszeripari Rt.',
        productName: 'Csomagolóanyag',
        amount: 200,
        deadline: '2025-05-10',
        unitPrice: 40000,
        createdAt: '2025-01-01 18:32:12',
        updatedAt: '2025-01-01 18:32:12',
        manufacturingProcesses: []
    },
    {
        id: '6',
        workId: '21740/117',
        customer: 'Elektronikai Kft.',
        productName: 'Nyomtatott áramkör',
        amount: 75,
        deadline: '2025-06-18',
        unitPrice: 150000,
        createdAt: '2025-01-01 18:32:12',
        updatedAt: '2025-01-01 18:32:12',
        manufacturingProcesses: []
    },
    {
        id: '7',
        workId: '21741/118',
        customer: 'Autószerelő Bt.',
        productName: 'Motoralkatrész',
        amount: 30,
        deadline: '2025-07-25',
        unitPrice: 75000,
        createdAt: '2025-01-01 18:32:12',
        updatedAt: '2025-01-01 18:32:12',
        manufacturingProcesses: []
    },
    {
        id: '8',
        workId: '21742/119',
        customer: 'Gépgyár Zrt.',
        productName: 'Csavar',
        amount: 1000,
        deadline: '2025-08-12',
        unitPrice: 250000,
        createdAt: '2025-01-01 18:32:12',
        updatedAt: '2025-01-01 18:32:12',
        manufacturingProcesses: []
    },
    {
        id: '9',
        workId: '21743/120',
        customer: 'Textilipari Kft.',
        productName: 'Ruházati kiegészítő',
        amount: 500,
        deadline: '2025-09-01',
        unitPrice: 100000,
        createdAt: '2025-01-01 18:32:12',
        updatedAt: '2025-01-01 18:32:12',
        manufacturingProcesses: []
    },
    {
        id: '10',
        workId: '21744/121',
        customer: 'Vegyipari Rt.',
        productName: 'Műanyag termék',
        amount: 300,
        deadline: '2025-10-15',
        unitPrice: 120000,
        createdAt: '2025-01-01 18:32:12',
        updatedAt: '2025-01-01 18:32:12',
        manufacturingProcesses: []
    }
]

const OrderEditor = () => {

    const urlParts = window.location.pathname.split('/')
    const id = urlParts[3] === 'create' ? undefined : urlParts[3]

    useTitle([
        {name: 'Megrendelés Lista', url: '/dashboard/orders'},
        {name: id ? 'Megrendelés' : 'Új Megrendelés', url: '/dashboard/orders/' + (id || 'create')}
    ])

    const [order, setOrder] = useState();
    const [message, setMessage] = useState('');
    const navigate = useNavigate()

    useEffect(() => {
        if (!id) return
        setOrder(orders.find(o => o.id === id))
    }, [id]);

    const onChange = (event) => {
        const {name, value} = event.target;
        setOrder((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const create = (e) => {
        e.preventDefault();
    };

    const update = (e) => {
        e.preventDefault();
    };

    const swapRecords = (index, otherIndex) => {
        setOrder(prev => {
            const newProcesses = prev.manufacturingProcesses;
            const temp = newProcesses[index];
            newProcesses[index] = newProcesses[otherIndex];
            newProcesses[otherIndex] = temp;

            return {
                ...prev,
                manufacturingProcesses: newProcesses
            };
        });
    };

    const onChangeItem = (event, itemIndex) => {
        const {value, name} = event.target;

        setOrder((prev) => {
            const updatedProcesses = prev.manufacturingProcesses.map((item, index) => {
                if (index === itemIndex) {
                    return {...item, [name]: value};
                }
                return item;
            });

            return {
                ...prev,
                manufacturingProcesses: updatedProcesses,
            };
        });
    };

    const addRow = () => {
        setOrder((prev) => {
            return {
                ...prev,
                manufacturingProcesses: [...(prev?.manufacturingProcesses || []), {}]} || { manufacturingProcesses: [{}] };

        });
    };

    const removeLastRow = () => {
        setOrder((prev) => {
            const updatedProcesses = prev?.manufacturingProcesses?.length > 0 ? [...prev.manufacturingProcesses.slice(0, -1)] : [];
            return {
                ...prev,
                manufacturingProcesses: updatedProcesses
            };
        });
    };

    return (
        <OrderEditorTemplate
            {...{
                id,
                order,
                message,
                onChange,
                create,
                update,
                navigate,
                swapRecords,
                onChangeItem,
                addRow,
                removeLastRow
            }}
        />
    );
};

export default OrderEditor;
