import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {Collapse} from '@mui/material';
import {AccountTree, AddBox, ChecklistRtl, DeleteSweep, FormatListNumbered, Group, Receipt} from '@mui/icons-material';
import {useNavigate} from 'react-router-dom';


export function MainListItems() {

    const navigate = useNavigate();
    const [selectedMenuItemId, setSelectedMenuItemId] = React.useState(null);
    const selectedMenuItem = {backgroundColor: "gray", color: "white"}
    const menuItems = [
        {
            label: "Megrendelések",
            icon: <FormatListNumbered/>,
            collapsible: true,
            open: true,
            toggleOpen: () => {
                navigate("/dashboard/orders")
            },
            subItems: [
                {path: "/dashboard/orders/create", label: "Új Hozzáadása", icon: <AddBox/>},
                {path: "/dashboard/orders/approvals", label: "Jóváhagyás", icon: <ChecklistRtl/>}
            ]
        },
        {path: "/dashboard/task-logs", label: "Munkafolyamatok", icon: <AccountTree/>},
        {path: "/dashboard/payrolls", label: "Számlák", icon: <Receipt/>},
        {path: "/dashboard/payrolls", label: "Munkavállalók", icon: <Group/>},
        {path: "/dashboard/payrolls", label: "Selejt Jelentések", icon: <DeleteSweep/>},
    ];

    return (
        <React.Fragment>
            {menuItems.map((item, index) => (
                <React.Fragment key={index}>
                    <ListItemButton
                        onClick={() => {
                            if (item.collapsible) item.toggleOpen();
                            else navigate(item.path)
                            setSelectedMenuItemId(index)
                        }}
                        sx={selectedMenuItemId === index ? selectedMenuItem : {}}
                    >
                        <ListItemIcon>
                            {item.icon}
                        </ListItemIcon>
                        <ListItemText primary={item.label}/>

                    </ListItemButton>

                    {item.collapsible && (
                        <Collapse in={item.open} timeout="auto" unmountOnExit>
                            {item.subItems.map((subItem, subIndex) => (
                                <ListItemButton
                                    key={subIndex}
                                    onClick={() => {
                                        navigate(subItem.path)
                                        setSelectedMenuItemId(1000 * (index + 1) + subIndex)
                                    }}
                                    sx={selectedMenuItemId === (1000 * (index + 1) + subIndex) ? selectedMenuItem : {}}
                                >
                                    <ListItemIcon style={{marginLeft: "20px"}}>
                                        {subItem.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={subItem.label}/>
                                </ListItemButton>
                            ))}
                        </Collapse>
                    )}
                </React.Fragment>
            ))}
        </React.Fragment>
    );
}