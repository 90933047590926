import {Alert, Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField} from "@mui/material"
import React from "react"
import {Add, ArrowDownward, ArrowUpward, Remove} from "@mui/icons-material";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

export default function OrderEditorTemplate(props) {

    const {id, onChange, create, update, message, navigate} = props

    const {
        workId,
        customer,
        productName,
        amount,
        deadline,
        unitPrice,
        createdAt,
        updatedAt,
        manufacturingProcesses = []
    } = props?.order || {}

    return (
        <div>
            <div className="white-paper">
                <form id="login" onSubmit={id ? update : create}>
                    <Grid container spacing={1} columns={20}>
                        {createInputField("workId", "Munkaszám", workId, onChange, true, true)}
                        {createInputField("customer", "Megrendelő", customer, onChange, true, true)}
                        {createInputField("productName", "Áru Neve", productName, onChange, true, true)}
                        {createInputField("amount", "Mennyiség", amount, onChange, true, true)}
                        {createInputField("deadline", "Határidő", deadline, onChange, true, true)}
                        {createInputField("unitPrice", "Egységár", unitPrice, onChange, true, true)}

                        {id !== undefined &&
                            <>

                                {createInputField("createdAt", "Létrehozva", createdAt, onChange, false, false, true)}
                                {createInputField("updatedAt", "Módosítva", updatedAt, onChange, false, false, true)}
                                <Grid item lg={10} xs={20}></Grid>
                            </>

                        }

                        {message &&
                            <Grid item lg={20} xs={20}>
                                <Alert severity="error" variant="standard" style={{color: "red"}}>{message}</Alert>
                            </Grid>
                        }

                        <Grid item lg={20} md={20} xs={20}></Grid>

                        <Grid item lg={2} md={5} xs={9}>
                            <Button type="button" fullWidth variant="contained" color="error"
                                    onClick={() => navigate(-1)}>
                                Vissza
                            </Button>
                        </Grid>

                        <Grid item lg={16} md={10} xs={2}></Grid>

                        <Grid item lg={2} md={5} xs={9}>
                            <Button type="submit" fullWidth variant="contained" color="success">
                                Mentés
                            </Button>
                        </Grid>

                    </Grid>
                </form>
            </div>
            <div className="white-paper" style={{marginTop: "16px"}}>
                <Grid container spacing={1} columns={30}>
                    <Grid item xs={30}>
                        <Typography sx={{fontSize: "22px", fontWeight: "bold"}}>Gyártásfolyamatok</Typography>
                    </Grid>
                    {manufacturingProcesses.length === 0 &&
                        <Grid item xs={30}>
                            <Typography sx={{marginLeft: "12px", fontSize: "18px", color: "lightgray", textAlign: "left", fontStyle: "italic"}}>A lista egyelőre üres...</Typography>
                        </Grid>
                    }

                    {props?.order?.manufacturingProcesses && Array.from({length: manufacturingProcesses.length}, (_, index) => (
                        <React.Fragment key={index}>
                            <Grid item xs={2} sx={{minWidth: "100px"}}>
                                <Button disabled={index === 0} type="button" variant="contained" sx={{
                                    padding: 0,
                                    marginLeft: "3px",
                                    marginRight: "3px",
                                    marginTop: "5px",
                                    minWidth: 0
                                }} onClick={() => props.swapRecords(index, index - 1)}>
                                    <ArrowUpward fontSize="large"/>
                                </Button>
                                <Button disabled={index === manufacturingProcesses.length - 1} type="button"
                                        variant="contained" sx={{
                                    padding: 0,
                                    marginLeft: "3px",
                                    marginRight: "3px",
                                    marginTop: "5px",
                                    minWidth: 0,
                                }} onClick={() => props.swapRecords(index, index + 1)}>
                                    <ArrowDownward fontSize="large"/>
                                </Button>
                            </Grid>

                            <Grid item xs={3}>
                                <FormControl sx={{m: 0}} fullWidth>
                                    <InputLabel id="type-label"
                                                sx={{marginTop: "-4px", "&[data-shrink='true']": {margin: "4px"}}}>Lépés
                                        típusa</InputLabel>
                                    <Select
                                        variant="outlined"
                                        labelId="type-label"
                                        name="type"
                                        label="Lépés típusa"
                                        value={manufacturingProcesses[index].type || ""}
                                        onChange={(e) => props.onChangeItem(e, index)}
                                        fullWidth
                                        sx={{'& div': {padding: "8px"}, margin: "4px", textAlign: "left"}}
                                    >
                                        <MenuItem value={"MANUFACTURING"}>Gyártás</MenuItem>
                                        <MenuItem value={"PURCHASING"}>Beszerzés</MenuItem>
                                        <MenuItem value={"COOPERATION"}>Kooperáció</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            {manufacturingProcesses[index].type === 'MANUFACTURING' && <>
                                {createInputField("name", "Megnevezés", manufacturingProcesses[index].name || "", (e) => props.onChangeItem(e, index), false, false, false, 3)}
                                {createInputField("voucherTime", "Utalvány Idő", manufacturingProcesses[index].voucherTime || "", (e) => props.onChangeItem(e, index), false, false, false, 3)}
                                {createInputField("materialTakeout", "Anyag Kivétel", manufacturingProcesses[index].materialTakeout || "", (e) => props.onChangeItem(e, index), false, false, false, 3)}
                                {createInputField("toolTakeout", "Eszköz Kivétel", manufacturingProcesses[index].toolTakeout || "", (e) => props.onChangeItem(e, index), false, false, false, 3)}
                                {createInputField("deadline", "Határidő", manufacturingProcesses[index].deadline || "", (e) => props.onChangeItem(e, index), false, false, false, 3)}
                                {createInputField("amount", "Mennyiség", manufacturingProcesses[index].amount || "", (e) => props.onChangeItem(e, index), false, false, false, 3)}
                                {createInputField("comment", "Megjegyzés", manufacturingProcesses[index].comment || "", (e) => props.onChangeItem(e, index), false, false, false, 7)}
                            </>}

                            {manufacturingProcesses[index].type === 'PURCHASING' && <>
                                {createInputField("source", "Kitől", manufacturingProcesses[index].source || "", (e) => props.onChangeItem(e, index), false, false, false, 6)}
                                {createInputField("subject", "Mit", manufacturingProcesses[index].subject || "", (e) => props.onChangeItem(e, index), false, false, false, 6)}
                                {createInputField("deadline", "Határidő", manufacturingProcesses[index].deadline || "", (e) => props.onChangeItem(e, index), false, false, false, 3)}
                                {createInputField("amount", "Mennyiség", manufacturingProcesses[index].amount || "", (e) => props.onChangeItem(e, index), false, false, false, 3)}
                                {createInputField("comment", "Megjegyzés", manufacturingProcesses[index].comment || "", (e) => props.onChangeItem(e, index), false, false, false, 7)}
                            </>}

                            {manufacturingProcesses[index].type === 'COOPERATION' && <>
                                {createInputField("partner", "Kinél", manufacturingProcesses[index].partner || "", (e) => props.onChangeItem(e, index), false, false, false, 6)}
                                {createInputField("subject", "Mit", manufacturingProcesses[index].subject || "", (e) => props.onChangeItem(e, index), false, false, false, 6)}
                                {createInputField("deadline", "Határidő", manufacturingProcesses[index].deadline || "", (e) => props.onChangeItem(e, index), false, false, false, 3)}
                                {createInputField("amount", "Mennyiség", manufacturingProcesses[index].amount || "", (e) => props.onChangeItem(e, index), false, false, false, 3)}
                                {createInputField("comment", "Megjegyzés", manufacturingProcesses[index].comment || "", (e) => props.onChangeItem(e, index), false, false, false, 7)}
                            </>}

                            <Grid item lg={30} md={30} xs={30}><Divider
                                style={{borderBottom: "2px dotted lightgray"}}/>
                            </Grid>
                        </React.Fragment>
                    ))}

                    <Grid item lg={30} md={30} xs={30}></Grid>

                    <Grid item xs={2} sx={{minWidth: "100px"}}>
                        <Button type="button" variant="contained" color="success" sx={{
                            padding: 0,
                            marginLeft: "3px",
                            marginRight: "3px",
                            marginTop: "5px",
                            minWidth: 0
                        }} onClick={() => props.addRow()}>
                            <Add fontSize="large"/>
                        </Button>
                        <Button type="button" color="error"
                                variant="contained" sx={{
                            padding: 0,
                            marginLeft: "3px",
                            marginRight: "3px",
                            marginTop: "5px",
                            minWidth: 0,
                        }} onClick={() => props.removeLastRow()}>
                            <Remove fontSize="large"/>
                        </Button>
                    </Grid>



                    <Grid item xs={30}>
                        <Grid container columns={20}>
                            <Grid item xs={18}></Grid>
                            <Grid item xs={2}>
                                <Button type="submit" fullWidth variant="contained" color="success">
                                    Mentés
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

function createInputField(name, label, value, onChange, required = false, autoFocus = false, disabled = false, xs = 5) {
    return (<Grid item xs={xs}>
        <TextField
            variant="outlined"
            margin="normal"
            required={required}
            fullWidth
            id={name}
            label={label}
            name={name}
            type="text"
            autoFocus={autoFocus}
            autoComplete="off"
            onChange={onChange}
            value={value || ""}
            disabled={disabled}
            sx={{'& input': {padding: "8px"}, margin: "4px"}}
            InputLabelProps={{sx: {marginTop: "-8px", "&[data-shrink='true']": {marginTop: "0px"}}}}
        />
    </Grid>)
}