import './App.css';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Dashboard from "./components/dashboard/Dashboard";
import '@fontsource/poppins';
import Orders from "./components/orders/Orders";
import {TitleProvider} from "./components/util/TitleContext";
import OrderEditor from "./components/orders/OrderEditor";
import Demo from "./components/demo/Demo";

function App() {
    return (
        <div className="App">
            <TitleProvider>
                <Router>
                    <Routes>
                        <Route exact path="/" element={<Dashboard content={<Orders/>}/>}/>
                        <Route exact path="/dashboard/orders" element={<Dashboard content={<Orders/>}/>}/>
                        <Route exact path="/dashboard/orders/create" element={<Dashboard content={<OrderEditor/>}/>}/>
                        <Route exact path="/dashboard/orders/approvals" element={<Dashboard content={<Demo/>}/>}/>
                        <Route exact path="/dashboard/orders/:id" element={<Dashboard content={<OrderEditor/>}/>}/>
                        <Route path="*" element={<Dashboard content={<Demo/>}/>}/>
                    </Routes>
                </Router>
            </TitleProvider>
        </div>
    );
}

export default App;
