import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { Grid } from '@mui/material';

const Demo = () => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setShow(true);
        }, 100);

        return () => {
            clearTimeout(timeout);
        };
    }, []);

    return (
        <div style={show ? styles.show : styles.hide}>
            <Grid container sx={{paddingTop: "20vh"}}>
                <Grid item lg={4}></Grid>
                <Grid item lg={4}>
                    <Paper elevation={10} style={styles.paper} variant='elevation'>
                        <Typography variant="h4" align="center" style={styles.comingSoon}>
                            Demo
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
};

const styles = {
    show: {
        opacity: 1,
        transform: 'translateY(0)',
        transition: 'opacity 1s, transform 1s'
    },
    hide: {
        opacity: 0,
        transform: 'translateY(-50vh)',
    },
    paper: {
        padding: '10px',
        backgroundColor: '#1976d2',
        borderRadius: "50px",
        borderBottom: "solid white 4px"
    },
    comingSoon: {
        color: 'white',
        fontSize: "90px",
        textShadow: '2px 2px 4px #888888',
    },
};

export default Demo;
