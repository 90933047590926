import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import {Button, Grid, Tooltip} from '@mui/material';
import {Add, CheckBox, Delete, GetApp} from '@mui/icons-material';
import {useNavigate} from 'react-router-dom';
import {useTitle} from "../util/TitleContext";
import {BarChart} from '@mui/x-charts/BarChart';
import {pieArcLabelClasses, PieChart} from "@mui/x-charts";
import {parseToMoney} from "../util/StringUtils";

const columns = [
    {id: 'workId', label: 'Munkaszám'},
    {id: 'customer', label: 'Megrendelő'},
    {id: 'productName', label: 'Áru Neve'},
    {id: 'amount', label: 'Mennyiség', fun: o => o.amount + ' db'},
    {id: 'deadline', label: 'Határidő'},
    {id: 'price', label: 'Teljes Ár', fun: o => (o.amount * o.unitPrice).toLocaleString() + " Ft"}
];

const rows = [
    {
        id: '1',
        workId: '21735/112',
        customer: 'Autógyár Kft.',
        productName: 'Címer',
        amount: 10,
        deadline: '2025-01-12',
        unitPrice: 35000,
    },
    {
        id: '2',
        workId: '21736/113',
        customer: 'Építőipari Zrt.',
        productName: 'Fém alkatrész',
        amount: 25,
        deadline: '2025-02-15',
        unitPrice: 12500
    },
    {
        id: '3',
        workId: '21737/114',
        customer: 'Bútorgyár Bt.',
        productName: 'Fa elem',
        amount: 50,
        deadline: '2025-03-20',
        unitPrice: 50000
    },
    {
        id: '4',
        workId: '21738/115',
        customer: 'Ipari Megoldások Kft.',
        productName: 'Csapágy',
        amount: 100,
        deadline: '2025-04-01',
        unitPrice: 300000
    },
    {
        id: '5',
        workId: '21739/116',
        customer: 'Élelmiszeripari Rt.',
        productName: 'Csomagolóanyag',
        amount: 200,
        deadline: '2025-05-10',
        unitPrice: 40000
    },
    {
        id: '6',
        workId: '21740/117',
        customer: 'Elektronikai Kft.',
        productName: 'Nyomtatott áramkör',
        amount: 75,
        deadline: '2025-06-18',
        unitPrice: 150000
    },
    {
        id: '7',
        workId: '21741/118',
        customer: 'Autószerelő Bt.',
        productName: 'Motoralkatrész',
        amount: 30,
        deadline: '2025-07-25',
        unitPrice: 75000
    },
    {
        id: '8',
        workId: '21742/119',
        customer: 'Gépgyár Zrt.',
        productName: 'Csavar',
        amount: 1000,
        deadline: '2025-08-12',
        unitPrice: 250000
    },
    {
        id: '9',
        workId: '21743/120',
        customer: 'Textilipari Kft.',
        productName: 'Ruházati kiegészítő',
        amount: 500,
        deadline: '2025-09-01',
        unitPrice: 100000
    },
    {
        id: '10',
        workId: '21744/121',
        customer: 'Vegyipari Rt.',
        productName: 'Műanyag termék',
        amount: 300,
        deadline: '2025-10-15',
        unitPrice: 120000
    }
]

const stats = {
    totalPaidIncome: 52873406,
    totalUnpaidIncome: 15373512,
    totalExpiredIncome: 685800,
    totalPaidExpense: 31247194,
    totalUnpaidExpense: 4583815,
    totalExpiredExpense: 13364,
    totalVat: 1646867,
    monthlyCosts: {
        "2024-01-01": [43000, 67000, 24000, 89000, 10000, 72000, 38000, 151000],
        "2024-02-01": [96000, 41000, 84000, 57000, 12000, 65000, 31000, 170000],
        "2024-03-01": [15000, 95000, 28000, 64000, 88000, 36000, 52000, 147000],
        "2024-04-01": [43000, 73000, 41000, 80000, 26000, 33000, 91000, 165000],
        "2024-05-01": [17000, 39000, 76000, 60000, 42000, 92000, 23000, 185000],
        "2024-06-01": [94000, 53000, 14000, 81000, 27000, 34000, 99000, 158000],
        "2024-07-01": [66000, 24000, 86000, 71000, 18000, 59000, 38000, 193000],
        "2024-08-01": [45000, 97000, 49000, 69000, 31000, 20000, 73000, 115000],
        "2024-09-01": [48000, 30000, 82000, 51000, 90000, 35000, 56000, 179000],
        "2024-10-01": [87000, 64000, 50000, 94000, 20000, 12000, 92000, 245000],
        "2024-11-01": [81000, 47000, 15000, 72000, 28000, 60000, 30000, 196000],
        "2024-12-01": [29000, 56000, 11000, 65000, 85000, 34000, 77000, 240000]
    }
}

const columnSums = () => {
    const result = Array(8).fill(0);

    for (const date in stats.monthlyCosts) {
        stats.monthlyCosts[date].forEach((value, index) => {
            result[index] += value;
        });
    }
    return result
}

const calculateTotal = (data, start, end) => data ? data.slice(start, end).reduce((acc, curr) => acc + curr, 0) : 0;
const calculateStartAngle = () => {
    const totalExpenses = columnSums()[7];
    const totalRevenues = calculateTotal(columnSums(), 0, 7);
    const max = Math.max(totalExpenses, totalRevenues)
    return Math.abs(1 - (totalExpenses + totalRevenues) / (2 * max)) * 100 * 3.6;
};

const pieChartData = [
    {
        id: 7,
        value: columnSums()[7],
        label: `Kiadás (${parseToMoney(columnSums()[7])})`,
        color: "rgba(255,0,0,0.75)"
    },
    {
        id: 0,
        value: columnSums()[0],
        label: `Tok (${parseToMoney(columnSums()[0])})`,
        color: "rgba(3,69,30,1)"
    },
    {
        id: 2,
        value: columnSums()[1],
        label: `Biléta  (${parseToMoney(columnSums()[1])})`,
        color: "rgba(3,69,30,0.8)"
    },
    {
        id: 3,
        value: columnSums()[2],
        label: `Kitűző  (${parseToMoney(columnSums()[2])})`,
        color: "rgba(12,120,52,1)"
    },
    {
        id: 1,
        value: columnSums()[3],
        label: `Kártyatartó  (${parseToMoney(columnSums()[3])})`,
        color: "rgba(21,177,80,0.8)"
    },
    {
        id: 4,
        value: columnSums()[4],
        label: `Díszdoboz  (${parseToMoney(columnSums()[4])})`,
        color: "#05f6ec",
    },
    {
        id: 5,
        value: columnSums()[5],
        label: `Jelvény  (${parseToMoney(columnSums()[5])})`,
        color: "rgba(118,96,237,0.8)",
    },
    {
        id: 6,
        value: columnSums()[6],
        label: `Aranynyomó  (${parseToMoney(columnSums()[6])})`,
        color: "rgba(211,168,2,0.8)"
    }
]

export default function Orders() {

    useTitle([
        {name: 'Megrendelések', url: '/dashboard/orders/'}
    ])

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const navigate = useNavigate();

    function valueFormatterWithPercentage(value, data) {
        const entry = Object.entries(stats.monthlyCosts)?.[data.dataIndex]?.[1]
        if (entry === undefined) return
        const totalExpense = entry.slice(0, 6).reduce((acc, curr) => acc + curr, 0);
        const percentage = totalExpense ? ((value / totalExpense) * 100).toFixed(1) : 0;
        return `${value.toLocaleString()} Ft (${percentage}%)`;
    }

    function valueFormatter(value) {
        return `${value?.toLocaleString()} Ft`;
    }

    return (
        <>
            <Grid container columns={20}>
                <Grid item xs={13}>
                    <Paper style={{margin: "10px 0px", padding: "16px 0px"}}>
                        {stats != null &&
                            <BarChart
                                dataset={Object.entries(stats.monthlyCosts).map(([key, value]) => {
                                    return {
                                        month: key.slice(0, 7),
                                        generalExpense: value[0],
                                        salaryPaymentExpense: value[1],
                                        vehicleExpense: value[2],
                                        taxPaymentExpense: value[3],
                                        operationalCostExpense: value[4],
                                        totalUnpaidExpense: value[5],
                                        totalPaidIncome: value[6],
                                        totalUnpaidIncome: value[7]

                                    }
                                })}
                                series={[
                                    {
                                        dataKey: "operationalCostExpense",
                                        label: "Tok",
                                        color: "rgba(3,69,30,1)",
                                        stack: 'income',
                                        valueFormatter: valueFormatterWithPercentage
                                    },
                                    {
                                        dataKey: "salaryPaymentExpense",
                                        label: "Biléta",
                                        color: "rgba(3,69,30,0.8)",
                                        stack: 'income',
                                        valueFormatter: valueFormatterWithPercentage
                                    },
                                    {
                                        dataKey: "taxPaymentExpense",
                                        label: "Kitűző",
                                        color: "rgba(12,120,52,1)",
                                        stack: 'income',
                                        valueFormatter: valueFormatterWithPercentage
                                    },
                                    {
                                        dataKey: "vehicleExpense",
                                        label: "Kártyatartó",
                                        color: "rgba(21,177,80,0.8)",
                                        stack: 'income',
                                        valueFormatter: valueFormatterWithPercentage
                                    },
                                    {
                                        dataKey: "totalPaidIncome",
                                        label: "Díszdoboz",
                                        color: "#05f6ec",
                                        stack: 'income',
                                        valueFormatter: valueFormatterWithPercentage
                                    },
                                    {
                                        dataKey: "generalExpense",
                                        label: "Jelvény",
                                        color: "rgba(118,96,237,0.8)",
                                        stack: 'income',
                                        valueFormatter: valueFormatterWithPercentage
                                    },
                                    {
                                        dataKey: "totalUnpaidExpense",
                                        label: "Aranynyomó",
                                        color: "rgba(211,168,2,0.8)",
                                        stack: 'income',
                                        valueFormatter: valueFormatterWithPercentage
                                    },
                                    {
                                        dataKey: "totalUnpaidIncome",
                                        label: "Kiadás",
                                        color: "rgba(255,0,0,0.75)",
                                        stack: 'expense',
                                        valueFormatter
                                    }
                                ]}


                                xAxis={[{scaleType: 'band', dataKey: 'month'}]}
                                yAxis={[{position: "left", valueFormatter: (value) => value.toLocaleString() + ' Ft'}]}
                                margin={{top: 50, bottom: 30, left: 110, right: 10}}
                                height={200}
                            />}

                    </Paper>
                </Grid>
                <Grid item xs={7} sx={{paddingLeft: "10px", height: "232px"}}>
                    <PieChart
                        series={[
                            {
                                data: pieChartData,
                                innerRadius: 20,
                                outerRadius: 116,
                                cx: 150,
                                paddingAngle: 1,
                                cornerRadius: 3,
                                arcLabel: (item) => (item.value / columnSums().slice(0, 7).reduce((acc, curr) => acc + curr, 0) * 100).toFixed(1) + '%',
                                arcLabelMinAngle: 10,
                                highlightScope: {fade: 'global', highlight: 'item'},
                                faded: {innerRadius: 30, additionalRadius: -30, color: 'gray'},
                                arcLabelRadius: 90,
                                startAngle: calculateStartAngle()
                            },
                        ]}
                        slotProps={{
                            legend: {
                                padding: 0,
                                labelStyle: {
                                    fontSize: 16
                                },
                            },
                        }}
                        sx={{
                            [`& .${pieArcLabelClasses.root}`]: {
                                fontWeight: 'bold',
                                fontFamily: 'Century Gothic',
                                fontSize: "14px",
                                fill: 'white',
                                shapeRendering: 'geometricPrecision'
                            }
                        }}
                        height={232}
                    />
                </Grid>
            </Grid>
            <Paper sx={{width: '100%', overflow: 'hidden'}}>
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell key="actions"
                                           style={{
                                               fontWeight: 700,
                                               background: "lightgray",
                                               width: "120px",
                                               paddingLeft: "12px"
                                           }}
                                           align='left' padding='none'>
                                    <Button variant='contained' color='success'
                                            sx={{minWidth: "0px", padding: "0px", marginRight: "10px"}}
                                            onClick={() => navigate("/dashboard/orders/create")}>
                                        <Add fontSize="large"/>
                                    </Button>
                                    <Button variant='contained' color='warning'
                                            sx={{minWidth: "0px", padding: "0px", marginRight: "10px"}}
                                            onClick={() => console.log("EXPORT")}>
                                        <GetApp fontSize="large"/>
                                    </Button>
                                </TableCell>
                                {columns.map((column) => (
                                    <TableCell key={column.id} align={column.align}
                                               style={{
                                                   fontWeight: 700,
                                                   background: "lightgray",
                                                   padding: "16px 10px 16px 10px"
                                               }}>
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows && rows
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                    return (
                                        <TableRow hover tabIndex={-1} key={row.id}>
                                            <TableCell key="actions" padding='none' align='left'>
                                                <Tooltip title='Törlés' placement='bottom'>
                                                    <Button variant='outlined' width='16px'
                                                            sx={{
                                                                minWidth: "0px",
                                                                padding: "0px",
                                                                marginLeft: "18px",
                                                                color: "#808080",
                                                                border: "none",
                                                                ':hover': {
                                                                    backgroundColor: " #D3D3D3",
                                                                    border: "none",
                                                                    color: "#696969"
                                                                }
                                                            }}
                                                            onClick={() => {
                                                            }}>
                                                        <Delete/>
                                                    </Button>
                                                </Tooltip>
                                                <Tooltip title='Jóváhagyás' placement='bottom'>
                                                    <Button variant='outlined' width='16px'
                                                            sx={{
                                                                minWidth: "0px",
                                                                padding: "0px",
                                                                marginLeft: "21px",
                                                                color: "#808080",
                                                                border: "none",
                                                                ':hover': {
                                                                    backgroundColor: " #D3D3D3",
                                                                    border: "none",
                                                                    color: "#696969"
                                                                }
                                                            }}
                                                            onClick={() => {
                                                            }}>
                                                        <CheckBox/>
                                                    </Button>
                                                </Tooltip>
                                            </TableCell>
                                            {columns.map((column) => {
                                                const value = row[column.id];
                                                return (
                                                    <TableCell key={column.id} align={column.align} padding="none"
                                                               onClick={() => navigate("/dashboard/orders/" + row.id)}
                                                               sx={{cursor: "pointer", padding: "10px"}}>
                                                        {column.fun ? column.fun(row) : value.toLocaleString()}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage="Elemek oldalanként:"
                />
            </Paper>
        </>
    );
}
