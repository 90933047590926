import * as React from 'react';
import {useContext, useState} from 'react';
import {createTheme, styled, ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import {MainListItems} from './listItems';
import {Tooltip} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {TitleContext} from "../util/TitleContext";

const drawerWidth = 260;
const defaultTheme = createTheme();

export default function Dashboard({content}) {

    const [open, setOpen] = useState(localStorage.getItem('menu-state') === "true" || true);
    const {title, text} = useContext(TitleContext);
    const navigate = useNavigate()
    const background = "lightcyan";

    const toggleDrawer = () => {
        localStorage.setItem('menu-state', !open);
        setOpen(!open);
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <Box sx={{display: 'flex'}}>
                <CssBaseline/>
                <AppBar position="absolute" open={open}>
                    <Toolbar sx={{pr: '24px'}}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={toggleDrawer}
                            sx={{
                                marginRight: '36px',
                                ...(open && {display: 'none'}),
                            }}
                        >
                            <MenuIcon/>
                        </IconButton>
                        <Typography
                            component="h1"
                            variant="h6"
                            color="inherit"
                            noWrap
                            sx={{flexGrow: 1, textAlign: 'left'}}
                        >
                            {title}
                        </Typography>
                        <Typography sx={{marginRight: "10px"}}>
                            {"Gipsz Jakab"}
                        </Typography>
                        <Tooltip title="Értesítések">
                            <IconButton color="inherit" onClick={() => navigate("/dashboard/notifications")}>
                                <Badge badgeContent={4} color="secondary">
                                    <NotificationsIcon/>
                                </Badge>
                            </IconButton>
                        </Tooltip>
                    </Toolbar>
                </AppBar>
                <Drawer id="drawer" variant="permanent" open={open}>
                    <Toolbar>
                        <IconButton color="inherit" sx={{marginLeft: "-12px", fontSize: "20px"}}
                                    onClick={() => navigate("/dashboard/change-logs")}>
                            v1.0.0
                        </IconButton>
                        <IconButton onClick={toggleDrawer} sx={{marginLeft: "auto"}}>
                            <ChevronLeftIcon/>
                        </IconButton>
                    </Toolbar>
                    <Divider/>
                    <List component="nav" sx={{overflowY: 'scroll', maxHeight: "65vh"}}>
                        {MainListItems()}
                    </List>
                </Drawer>
                <Box component="main" id="main" sx={{
                    flexGrow: 1,
                    height: '100vh',
                    padding: '84px 20px 0px 20px',
                    overflow: 'auto',
                    background: background
                }}>
                    {content}
                </Box>
            </Box>
        </ThemeProvider>
    );
}

const AppBar = styled(MuiAppBar, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
    }));

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: 0,
            }),
        },
    }),
);
