import {ArrowForwardIos} from '@mui/icons-material';
import {Link} from '@mui/material';
import React, {createContext, useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

export const TitleContext = createContext();

export const TitleProvider = ({children}) => {
    const [title, setTitle] = useState('');
    const [text, setText] = useState('');

    return (
        <TitleContext.Provider value={{title, setTitle, text, setText}}>
            {children}
        </TitleContext.Provider>
    );
};

export const useTitle = (pageArray, object = undefined) => {

    const {setTitle} = useContext(TitleContext);
    const {setText} = useContext(TitleContext);
    const navigate = useNavigate()

    useEffect(() => {
        if (pageArray) {
            setText(pageArray[pageArray.length - 1].name)
            setTitle(
                <>
                    {pageArray.length === 1 ? <>{pageArray[0].name}</> :
                        pageArray.map((item, index) => (
                            <React.Fragment key={index}>
                                {index > 0 && <ArrowForwardIos sx={{marginTop: "6px"}}/>}
                                <Link onClick={() => navigate(item.url)}
                                      component="button"
                                      sx={{
                                          padding: "0px 5px 0px 5px",
                                          marginTop: (pageArray.length > 1 ? "-10px" : ""),
                                          color: 'white',
                                          textDecoration: 'none',
                                          ":hover": {"WebkitTransform": "scale(1.03)"}
                                      }}>
                                    {item.name}
                                </Link>
                            </React.Fragment>
                        ))}
                </>
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setTitle, setText, object]);
};
